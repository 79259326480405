import React, { useState, useRef, useEffect } from 'react';
import searchIcon from '../../images/SearchIcon1.png'; // Adjust the path as needed

const SearchComponent: React.FC<{
  searchKeyword: string;
  handleSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getThemes: any;
}> = ({ searchKeyword, handleSearchInputChange, getThemes }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsSearchVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={searchRef} className="relative">
      <div
        onClick={toggleSearch}
        className="flex items-center cursor-pointer"
      >
        <img 
          src={searchIcon} 
          alt="Search" 
          className="w-10 h-10 mr-2" // Adjust size as needed
        />
        <span>Search Properties</span>
      </div>
      {isSearchVisible && (
        <div className="op-full -left-16 -mt-10 w-64">
          <input
            type="text"
            id="searchProperty"
            className="py-2 px-3 w-full border-primarycolor border-2 rounded-md text-sm"
            placeholder="Enter Street Name or Postcode"
            value={searchKeyword}
            onChange={handleSearchInputChange}
            style={{
              borderColor: getThemes?.color1 === "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes?.color1,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SearchComponent;